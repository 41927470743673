@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
body {
 -moz-background-size: cover;
 -o-background-size: cover;
 background-size: cover;

}

#opacity {
 position: absolute;
 top: 0;left:0;
 z-index: 10000;
 width: 100%;height: 100%;
}

#opacity ul li {
   list-style-type:none;
   float:left;
   margin:10px;
   position:relative;
}
#opacity ul li:after{
   content:'';
   position:absolute;
   top:-9999px;
   left:-9999px;
   width:100%; height:100%;
   border-color: rgba(0,0,0,.6);
   border-left-width: 9999px;
   border-top-width:9999px;
   border-bottom-width:9999px;
   border-right-width:20px;
   border-style:solid;
}
#opacity ul li:nth-child(2):after{
   left:0px;
   border-left-width: 0;
   border-top-width:9999px;
   border-bottom-width:9999px;
   border-right-width:9999px;
}
.mask {
   background:transparent;
   padding:50px;
}

.Dropdown-control {
  /* position: relative; */
  /* overflow: hidden; */
  /* background-color: white; */
  /* border: 1px solid #ccc; */
  /* border-radius: 2px; */
  /* box-sizing: border-box; */
  /* color: #fff; */
  /* cursor: default; */
  /* outline: none; */
  /* padding: 8px 52px 8px 10px; */
  /* transition: all 200ms ease; */
  background: transparent!important;
  border: none!important;
  border: 2px solid #b0b0b01f!important;
  border-radius: 3px!important;
  color: white!important;
  width: 100%!important;
  padding: 12px!important;
  height: 45px!important;
  margin-top: -11px !important;
}
.Dropdown-menu{
  background-color:rgba(255,255,255,0.8) !important;
  border-radius: 5px ;
}

.ItemPressed {
  display: flex;
  flex-direction: row;
}
.ItemContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.ItemCheck {

  border-radius: 1px;
  border-color: lightgray;
  border-width: 1px;
  border: 0.5px solid lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  color: lightgray;
  padding: 4px;
  display: inline-block;
  margin: 3px;
  font-size: 11px;
  line-height: 1.1;
}
.ColumnContainer {
  display: flex;
  justify-content: flex-start;
}
.ColumnId {
  padding: 3px;
}
.Title {
  color: lightgray;
  font-size: 12px;
  font-family: Poppins;
  margin-bottom: 5px;
  font-weight: 300;
}
.TitleUnder {
  font-size: 10px;
  font-weight: 500;
  color: #F0A202;
  letter-spacing: 0.1px;
}
.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
*,html{
  box-sizing: border-box;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
