body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.AuthArmor__active-tab___xIoVB{
  background:#f0a202 !important;
}
.AuthArmor__disabled___zIwnL
{
  background: #f0a202 !important;
}
.AuthArmor__container___M3LwV p {
  margin: 0;
  font-family: 'Poppins';
}
.AuthArmor__popup-overlay___UKXCV .AuthArmor__auth-message___mBDui{
  background-color: #f0a202 !important;
  
  font-family: 'Poppins' !important;
}
.AuthArmor__btn___rdTuZ
{
  background: #f0a202 !important;
}
.PopUP_Holder_big {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 222222222222;
  background: rgba(0,0,0,0.8);
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding-block: 10vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.CloseIts{
  position: absolute;
  top: 30px;
  right: 30px;
  background: rgb(187, 125, 6);
  border-radius: 5px;
  padding: 5px;
}
.PopUpFont_bug_big {
  /* padding: 3px; */
  background: #262626;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.PopUpFont_bug_tx {
  color: white;
  font-family: 'Poppins';
}
.PopUpFont_bug {
  padding: 10px;
  /* background: #262626; */
  font-family: 'Poppins';
  letter-spacing: 0.2px;
  /* overflow-x: scroll; */
  color: darkgray;
}
.PopUP_Holder_big_Container{

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  flex-direction: column;
  background: rgba(255,255,255,0.1);
  height: fit-content;
  border-radius: 5px;
  max-width: 80%;
  padding: 23px;
  position: relative;
}
.TradeContainer{
  width: "100%";
}
*, html {
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none   !important;  /* Firefox */
}

::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
html
{background: #1C202B;
  width: 100vw;
  height: 100vh;
}
.ShowingwithdrawContainers{
  display: none
}

.ShowingwithdrawContainers_form {
  display: block;
  position: fixed;
  background: rgba(0,0,0,0.8);
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  z-index: 43434;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CloseContainer_form {
  position: absolute;
  top: 30px;
  right: 30px;
  background: rgb(187, 125, 6);
  border-radius: 5px;
  padding: 5px;
}
.SideNav {
  width: 90px;
  height: 100vh;
  display: flex;
  position: fixed;
  flex-direction: column;
  align-items: center;
  background: rgb(255,255,255,0.1);
}
.Wallets{
  
    width: 100%;
    height:calc(100vh - 90px );
    overflow-y: scroll;
    padding: 1.5%;
  
}
.WalletContaienr
  { width: 100%; display: flex; gap: 10px
}
.WalletContais{
  
    width: 35%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    border-radius: 15px;
    padding: 12px;
    background: rgba(255,255,255,0.1)
  
}
.withdrawContainersAbsolute{
  background-color: rgb(255,255,255,0.1);
}
.statiscts{
   width: 100%; padding: 1.5%
}
.StatisctsContainer{
   width: 100%;display: flex; gap: 10px
}
.ChartsName {
  font-family: 'Poppins';
  letter-spacing: 1px;
  color: white;
  font-weight: 200;
}
.ChartsRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px; 
}
.swiper-pagination-bullet-active{
  background: #bb7d06 !important;
}
.overViewTitle {
  padding-bottom: 10px;
  color: white;
  font-family: 'Poppins';
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 13px;
}
.overViewContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background: rgba(255,255,255,0.1);
  border-radius: 15px;
  flex-direction: column;
  height: 340px;
  padding: 12px;
}
.overStyles{
  display: flex;
  width: 100%;
  margin-top: 15px;
  gap: 10px;
}
.bigTitle{ 
  font-size: 2.5em;
  font-weight: 400;
  line-height: 30px;
  color: #0fa209;
  font-family: 'Poppins';
}
.Input {
  width: 100%;
  margin-top: 10px;
}
.Input_extra{
  width: 100%;
}
.InputTitle {
  background: #bb7d06;
  margin-left: 10px;
  padding: 3px;
  width: fit-content;
  border-radius: 4px;
  font-family: 'Poppins';
  font-size: 0.7em;
}
.DashboardItems{

  padding: 1.5%;
  padding-top: 0px;
  padding-bottom: 0px;
  gap: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;

}

.TokenContainer{ width: calc(100% - 310px) }
.MainDashContainer{ width: 100%;display: flex; gap: 10px }
.MainDash
{ width: 70%
}
.Seperator
  { width: 20px; height: 10px
}
.AmountSwiper{
    padding: 12px;
    background: rgba(255,255,255,0.1);
    border-radius: 15px;
    width: 250px;
  
}
.ChartTopContainer
  { width: 100%; display: flex; gap: 10px
}
.SideBarDash{ width: calc(29%) }
.NewBlog{
  
    width: 100%;
    background: rgba(255,255,255,0.1);
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 15px;
    height: calc(40vh - 126px);
  }
.Dashboard{

  width: calc(100vw - 90px);
  margin-left: 90px;

}
.LogoContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
}


.withdrawContainers{
  width: 64%;
  height: calc(100vh - 90px - 302px - 20px);
  display: flex;
  margin-top: 10px;
  border-radius: 15px;
  background: rgba(255,255,255,0.1)
}
@media only screen and (max-width: 1400px) {
  
  .DashboardItems{
flex-direction: column;
  }
  .MainDash{
    width:100%
  }
  
}
.ChartTotalContainer{

  width: 64%;
}
.ChartContainer{
  width: "100%";
  height: calc(100vh - 90px - 302px - 20px);
  display: flex;
  border-radius: 15px;
  background: rgba(255,255,255,0.1);
}
.WithdrawAvailbeContaie{
  display: flex;
}


.TradeTopContainer{

  width: 35%;}

.TradeContainer{

  height: calc(100vh - 90px - 302px - 20px);
  display: flex;
  flex-direction: column;
  
  border-radius: 15px;
  padding: 12px;
  background: rgba(255,255,255,0.1);
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}
.TokenBubble{
  min-width:232px
}
.InputField input {
  background: transparent;
  border: none;
  border: 2px solid #b0b0b01f;
  border-radius: 3px;
  width: 100%;
  height: 45px;
  color: white;
  margin-top: -24px;
}
.extraBigTitle{ 

  font-size: 2.5em;
  font-weight: 400;
  color:white;
  font-family: 'Poppins';
}
.hideit{
  display:none;
}
.TokensContainers{
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  overflow-x: scroll;
}
input.react-dropdown-select-input.css-1c8t16q-InputComponent.e11wid6y0::placeholder {
color:white
}

.ColumnId {
  padding: 3px;
  width: 100%;
  min-width:  120;
  justify-content: space-between  ;
  align-items: flex-start;
  margin-top: 20px;
    display: flex;
  flex-direction: row;
  border-bottom: 0.2px solid rgba(169, 169, 169, 0.153);
}


.MarketChane {
  font-size: 12px;
  font-family: 'Poppins';
  font-weight: 400;
}
.Title {
  color: lightgray;
  font-size: 12px;
  font-family: Poppins;
  margin-bottom: 5px;
  font-weight: 300;
}
.TitleUnder {
  font-size: 10px;
  font-weight: 500;
  color: #F0A202;
  letter-spacing: 0.1px;
}
.css-b62m3t-container
.css-13cymwt-control {
  background: #bb7d0a !important;
  border: none !important;
  border-radius: 5px;
  scale: 1;
  font-size: 0.8em;
  width: 100p;
}
.css-b62m3t-container{
scale: 0.8  ;
margin-left: -10px
}
input.react-dropdown-select-input.css-1f08v4e-InputComponent.e11wid6y0::placeholder {
  color: white;
}
.react-dropdown-select.undefined.css-wmy1p7-ReactDropdownSelect.e1gzf2xs0 {
    border: none;
    background: #bb7d06;
    border-radius: 6px;
    font-size: 0.8em;
    font-family: 'Poppins';
    font-weight: 400;
}
.TokenBubble{

  background: rgba(255,255,255,0.1);
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 15px;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  min-width: 270px;

}
.LivePrice {
  font-family: 'Poppins';
  font-size: 0.8em;
  letter-spacing: 2px;
  font-weight: 300;
  padding-top: 2px;
  color: lightgray;
}
span.TokenBu {
  font-weight: 400;
  color: #bb7d06;
}

.PopUP_Holder{

  position: fixed;
  top: 29px;
  left: 20px;
  width: calc(100% - 40px);
  height: 70px;
  display: flex;
  padding: 12px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #4a4a4a;
  border-radius: 10px;
  gap:20px;
  animation: 0.2s ease-out 0s 1 slideInFromLeft;
}
@keyframes slideInFromLeft {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
.PopUpFont{

    font-family: 'Poppins';
    color: white;
    font-weight: 200;
    letter-spacing: 1px;
}
.symbolCoin {
  padding: 3px;
  background: #bb7d06;
  border-radius: 2px;
  font-family: 'Poppins';
  font-size: 0.6em;
  letter-spacing: 2px;
}
.AmountCounter {
  display: flex;
  
  font-weight: 400;
  color:darkgray;
  font-family: poppins;

}
.TopContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 6px;
  gap: 30px;
  padding-bottom: 6px;
}
.TokenBu{
  margin-left: 3px;
}
.TokenBubbleImage{
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
}
.TokenBubbleImage img{
  width: 20px;
  height: 20px;
  object-fit: contain;
}.mediemSize {
  font-size: 0.9em;
  font-weight: 300;
  font-family: 'Poppins';
  color: white;
}
.smallSize{
  font-size: 0.8em;
  font-weight: 400;
  font-family: 'Poppins';
  color:white;
}
.ItemHeader{
  display: flex;
  justify-content: space-between;
  padding: 3%;
  padding-top: 0;
  padding-bottom: 0;
  align-items: center;
  height: 90px;
}
.ItemHeaderTitle{

  font-weight: 700;
  color: #bb7d06;
  font-size: large;
  font-family: 'Poppins';
  letter-spacing: 1px;
}
.accountTitle{

  font-family: 'Poppins';
  padding: 6px;
  background: #bb7d06;
  border-radius: 5px;
}
.NavElement {
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  color: white;
}
.subItem{
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;}
.NavElement p {
  font-size: 11px;
  font-family: 'Poppins';
  line-height: 0px;
}
.selected{

  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  background: rgba(255,255,255,0.1);
  align-items: center;
  border-left: 4px solid #bb7d06;
}
.Menu{

  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 0.7em;
  font-family: 'Poppins';
  letter-spacing: 0.2px;
  color: lightgray;
}
.sperator{
  border-right: 1px solid lightgray;
  height: 20px;
  margin: 0 10px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.WebsiteHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 86%;
  margin-left: 8%;
}
.headerText{

  font-size: 2em;
  font-family: 'Poppins';
  font-weight: 300;
  /* letter-spacing: 1px; */
  color:darkgrey;

}
.SignIn {
  padding: 14px;
  /* background: #d18671; */
  display: flex;
  justify-content: center;
  border-radius: 5px;
  align-items: center;
  font-size: 1em;
  width: fit-content;
  background: #bb7d0a;
  color: antiquewhite;
  font-family: 'Poppins';
  width: 130px;
  height: 35px;
  font-weight: 500;
}
.Names{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;}

  .HorSpe {
    border-radius: 2px;
    margin: 10px;
    height: 40px;
    width: 3px;
    margin-left: 0px;
    background-color: darkgray;
  }
  .HeaderContainer{
    
      width: 100%;;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  
.ColumnIdd {
  padding: 3px;
}
.TitleIdd {
  color: lightgray;
  font-size: 12px;
  font-family: Poppins;
  margin-bottom: 5px;
  font-weight: 300;
}
.TitleIddUnder {
  font-size: 10px;
  font-weight: 500;
  color: #F0A202;
  letter-spacing: 0.1px;
}
.Pricelist{
  justify-content: center;
}
.accountContainer{

  display: flex;
  color: white;
  gap: 20px;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins';
}
.StatiscsContainer{
  display: flex;
  gap: 10px;
}
@media only screen and (max-width: 960px) {

.NavElement p {
  display: none;
}
  .Liners{
    width:300px
  }
  .StatiscsContainer{
    flex-direction: column;
  }
  .Pricelist{
    justify-content: flex-start;
  }
  .HeaderContainer{
    
    justify-content: center;
    align-items: center;
  }
.Names{
  flex-direction: column;
  gap:10px
}
.HorSpe {
  border-radius: 2px;
  margin: 10px;
  height: 2px;
  width: 130px;
  margin-left: 0px;
  background-color: darkgray;
}
  .WebsiteHeader{
    flex-direction: column;
  }
  .LogoContainer{

    height: 60px;
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .NavElement {
    height: 40px;
    display: flex;
    width: 20%;
    justify-content: center;
    align-items: center;
}
.selected {
  height: 30px;
  width: 35% ;
  display: flex;
  justify-content: center;
  background: rgba(255,255,255,0.1);
  align-items: center;
  border-left: 4px solid #bb7d06;
}

.TradeTopContainer{

  width: 100%;}
  .SideNav {
    width: 100vw;
    z-index: 4242;
    height: 70px;
    display: flex;
    position: fixed;
    bottom: 0;
    flex-direction: row;
    align-items: center;
    background: rgb(51 55 65);

    border-top: 1px solid #777777;
}
.Dashboard{
  width: 100vw;
  padding-bottom:100px;
  margin-left: 0;
}
.MainDashContainer{
  flex-direction: column;
}
.AmountSwiper{
  width: 100%;
}
.ChartTopContainer{
  flex-direction: column;
}
.overView{
  width: 100% !important;
}
.TokenContainer{
  width: 100% !important;
}
.ChartTopContainer{
  width: 100% !important;
}
.ChartTotalContainer{
  width: 100% !important;
}
.ChartContainer{
  width: 100% !important;
}


.TradeTopContainer{

  width: 100% !important;
}
.WithdrawAvailbeContaie{
  flex-direction: column;
}
.NewBlog{
  width: 100% !important;
}
.SideBarDash{
  width: 100% !important;
}
.ItemContainer {
  display: flex;
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  gap: 7px !important;
  margin-bottom: 10px !important;
}

.ChartTotalContainer {
  width: 64%;
}
.ChartContainer {
  width: 100%;
  height: auto;
  display: flex;
  border-radius: 15px;
  padding-top: 27px;
  background: rgba(255,255,255,0.1);
}
.WalletContais{
width: 100%;
}
.withdrawContainers{
  display: none;
}
.CloseContainer {
  position: absolute;
  top: 30px;
  right: 30px;
  background: rgb(187, 125, 6);
  border-radius: 5px;
  padding: 5px;
}
.withdrawContainersAbsolute {
  background-color: rgb(0 0 0);
  width: 90%;
  /* padding: 12px; */
}
.ShowingwithdrawContainers {
  display: block;
  position: fixed;
  background: rgba(0,0,0,0.8);
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  z-index: 43434;
  display: flex;
  justify-content: center;
  align-items: center;
}
.TokensContainers{
  overflow-x: auto;
}
.Wallets{
overflow: visible;
height: auto;
}
.headerText {
  font-size: 1.2em;
  font-family: 'Poppins';
  font-weight: 300;
  /* letter-spacing: 1px; */
  text-align: center;
  color: darkgrey;
}
.Menu{
  display: none;
}
}